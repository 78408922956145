.about-us-container {
    padding-bottom: 20px;
    justify-content: center;
    display: grid;
    justify-items: center;
    width: 100%;

}

.about-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;
  align-items: center;
  justify-items: center;
  margin-top: 20px;
  width: 80%;
}
  
.about-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

.center-about{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: normal;
    width: 100%;
    
}

.icon-size {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  max-width: 200px;
  padding: 16px;
  }
.icon-size-img1 {
  max-width: 600px;
  width: auto;
  height: auto;
  }

@media screen and (max-width: 917px) {
    .about-content {
        grid-template-columns: 1fr;
        width: 100%;
      
    }
    .icon-size-img1 {
        width: 500px;
        height: auto;
    }
    .icon-size-img2 {
        width: 500px;
        height: auto;
    }
  
}
@media screen and (max-width: 768px) {
  .center-about {
      margin-inline: 30px;
      width:auto;
      margin-top: 20px;
      gap: 20px;
  }
  
  .icon-size-img1 {
      width: inherit;
      height: auto;
    
  }
  .icon-size-img2 {
      width: inherit;
      height: auto;
      width: 80%;
      
  }
}


.icon-size-img2 {
  max-width:fit-content;
  max-height: 400px;
  }

.about-title {
    font-weight:500;
    font-size: 1.5rem;
    margin: 20px 0;
    text-align: center;
    width: 100%;
  }

.paragraph {
    max-width: 100%;
    text-align: center;
    width: auto,
  }
@media screen and (max-width: 768px) {
    .about-title {
        font-size: 1.2rem;
    }
    .paragraph {
        width: 80%;
    }
  
}  
  
.about-link {
    margin-top:0px;
  }
  
.values-container {
  display: flex;
  align-items:center;
  justify-content: center;
  }
.values-container-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0px;
  }
  
.value-item {
    text-align: center;
  }
  
.value-item img {
    width: auto;
    height: 100px;
  }
.icon-container-plat{
  background-color: #A7D9B3;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  
  /* Responsiveness */
@media (max-width: 768px) {
.about-content, .values-container {
      grid-template-columns: 1fr;
    }
  }

   
.about-title {
    font-family:'Montserrat';
    font-size: 2.8rem;
    font-weight: 550;
    color:black;
    margin: 24px; 
    text-align: center;
    justify-content: center;
    display: flex;
    padding: 16px;
}
@media screen and (max-width: 1200px) {
    .about-title {
        
       font-size: 2.5rem;
       padding: 8px;
    }
}
@media screen and (max-width: 768px) {
    .about-title
        {
        font-size: 2rem;
        padding: 0;
    }
    
}
