
/* black */
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-Black.ttf');
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-BlackItalic.ttf');
  font-style: italic;
  font-weight: 900;
}
/* extra bold */
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-ExtraBold.ttf');
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-ExtraBoldItalic.ttf');
  font-style: italic;
  font-weight: 800;
}
/* bold */
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-Bold.ttf');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-BoldItalic.ttf');
  font-style: italic;
  font-weight: bold;
}
/* semi bold */
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-SemiBoldItalic.ttf');
  font-style: italic;
  font-weight: 600;
}
/* medium */
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-MediumItalic.ttf');
  font-style: italic;
  font-weight: 500;
}
/* regular - normal */
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-Regular.ttf');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-Italic.ttf');
  font-style: italic;
  font-weight: normal;
}
/* light */
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-Light.ttf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-LightItalic.ttf');
  font-style: italic;
  font-weight: 300;
}
/* extra light */
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-ExtraLight.ttf');
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-ExtraBoldItalic.ttf');
  font-style: italic;
  font-weight: 200;
}

/* thin */
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-Thin.ttf');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'montserrat';
  src: url('../src/css/fonts/Montserrat/static//Montserrat-ThinItalic.ttf');
  font-style: italic;
  font-weight: 100;
}




*{
  font-family: montserrat;
}
body {
  margin: 0;
  font-family: montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cards-container{
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  justify-content: space-around;
  margin-left: 120px;
  margin-right: 120px;
  margin-top: 0px;
  margin-bottom: 16px;
}
@media screen and (max-width: 768px) {
  .cards-container{
    margin-left: 16px;
    margin-right: 16px;
    justify-content: center;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  
}
.coil-container{
  width: auto;
  justify-items: center;
  text-align: start;
}
.section-coil{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-inline:16px;
}
div h4 {
  color: #80225f;
}
.font-size-coil{
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .font-size-coil{
    font-size: 12px;
  }
}


.coil-values-container{
margin-left: 14px;
}
.center-elements{
  display: flex;
  align-items: center;
  flex-direction: column;
}

:root {
  --primary-color: rgba(231, 153, 175, 0.7);
  --primary-color-dark: #ff9bb2;
  --secondary-color: #80225f;
  --secondary-color-light: #a6648f;
  --green-color: #b0d7b3;
  --green-color-light: rgba(176, 215, 179, 0.7);
  --gray-color: #808080;
  --gray-color-light: #808080b4;
  --gray-icon: #b1b1b1;
  --background-white: #fff;
  --color-active-icon: #fca4bc;
  --button-white-color: rgba(255, 255, 255, 0.7);
  --button-white-color-active: rgba(255, 255, 255, 1);
  --shadow-button: 2px 3px 8px 2px rgba(0, 0, 0, 0.15);
  --shadow-input: 2px 1px 1px 1px rgba(0, 0, 0, 0.10);
  --text-white: #ffffff;
  --height-navbar: 70px;
  --height-navbar-movil: 70px;
  --height-card-list: 10vw;
  --border-radius: 10px;
  --border-radius-icon-title: 5px;
  --red-color: #f34242;
  --tittle-color: #747d8c;
  --text-black: #4d4d4d;
  --tittle-colorbody: #4c4c4c;
  --background-color: #f1f2f6;
  --background-color-transparent: rgba(250, 250, 250, 0.7);
  /*--primary-color: #551f93;*/
  --background-blue: rgba(153, 208, 231, 0.7);
  --bg-blue: var(--primary-color);
  --background-gray: #e6e6e6;
  --background-gray-contacts: #efefef;
  --placeholder-color: #ced6e0;
  --paragraph-color: #747d8c;
  --color-line: rgb(184, 184, 184, 0.3);
  /*--color-active-icon: #551f93;*/
  
  /*--text-font-sizes*/
  --title-text: 1.6em;
  --title-card-small: 0.9em;
  --subtitle-text: 1.2em;
}
/* .scroll-menu {
  overflow: auto;
  scrollbar-color:  var(--secondary-color-light) #b3b3b3;
  scrollbar-width: thin;
} */
.scroll-menu {
  overflow: auto;
  scrollbar-color:  var(--secondary-color-light) #b3b3b3;
  scrollbar-width: thin;
}

/* Tamaño del scroll */
.scroll-menu::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 20px;
}

.scroll-menu::-webkit-scrollbar:horizontal {
  height: 3px;
}

/* Estilos barra (thumb) de scroll */
.scroll-menu::-webkit-scrollbar-thumb {
  background: var(--secondary-color-light);
  border-radius: var(--border-radius);
}
.scroll-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px red;
  border-radius: 10px;
}

.scroll-menu::-webkit-scrollbar-track-piece:vertical {
  background: #aa0a0a;
  border-radius: var(--border-radius);
}

.scroll-menu:focus::-webkit-scrollbar-thumb,
.scroll-menu:hover::-webkit-scrollbar-thumb {
  background: var(--secondary-color-light);
  border-radius: 8px;
  display: block;
}

.scroll-menu::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.scroll-menu::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}