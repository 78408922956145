.pill-btn {
  display: inline-block; /* Permite que el ancho se ajuste al contenido */
  box-sizing: border-box;
  height: 44px;
  line-height: 44px;
  background: #F2A2C0;
  padding: 0px 20px; /* Aumenta el padding horizontal para dar espacio al contenido */
  border-radius: 999em;
  text-decoration: none;
  color: black;
  font-size: 15px;
  vertical-align: bottom;
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0px;
  transition: .15s background-color, .15s border-color, .15s color, .15s fill;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center; /* Centra el contenido dentro del botón */
  margin: 8px;
}

.pill-btn:hover {
  background: #8C2769;
  border: 3px solid #F2A2C0;
  color: #F2A2C0;
}

.icon-btn-size {
  height: 30px;
}

