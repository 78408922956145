.content-product-bag-container {
  display: flex;
  cursor: pointer;
  width: 80%;
  -webkit-box-shadow: var(--shadow-button);
  height: fit-content;
  -moz-box-shadow: var(--shadow-button);
  justify-content: center;
  box-shadow: var(--shadow-button);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  background-color: var(--background-white);
  align-items: center; /* Centra verticalmente */
  margin: 16px;

  gap: 1.5rem;
}
@media screen and (max-width: 768px) {
  .content-product-bag-container {
    flex-direction: column;
    align-items: center; /* Asegura que los elementos estén centrados en pantallas pequeñas */
    gap: 0;
  }
  
}

@media screen and (max-width: 1000px) {
  .content-product-bag {
    flex-direction: column;
    align-items: center; /* Asegura que los elementos estén centrados en pantallas pequeñas */
    padding-bottom: 20px;
    padding-top: 20px;
    gap: 1rem;
  }
}

.content-product-bag {
  width: 100%;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between; /* Distribuye espacio entre los elementos */
  align-items: center; /* Alinea verticalmente los elementos */
  margin-left: 16px;
}


.content-product-card-list {
  box-shadow: var(--shadow-button);
}

.content-name-product-bag {
  flex-grow: 0.9;
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 0px;
}

.content-img-card-list-p {
  width: 20vh;
  height: 20vh;
  align-items: center;
}

.img-content-card-list-pr {
  width: 20vh;
  height: 20vh;
  object-fit: cover;
}

.content-text-product-bag {
  display: flex;
  flex-direction: column;
  text-align: justify;
  padding: 16px;
  gap: 1rem;
}
@media screen and (max-width: 1000px) {
  .content-text-product-bag {
    padding-left:0 ;
    padding-bottom: 0;
  }
  
}


.title-product-bag {
  color: black;
  font-weight: 500;
  text-align: center;
  font-size: 1.5rem;


}

.decription-product-bag {
  color: var(--gray-color);
  font-size: 1rem;
  flex-grow: 1;
}

.content-options-product-bag {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra horizontal y verticalmente */
  justify-content: center;
  width: 100%; /* Asegura que ocupe todo el ancho disponible */   

}

.content-options-btns-product-bag {
  display: flex;
  align-items: center;
}

.total-product-bag {
  padding: 15px 20px;
  font-size: 2rem;
  text-align: center;
  cursor: pointer;
  outline: none;
  width: 100%;
  color: var(--secondary-color);
  background-color: var(--green-color-light);
  border: none;
  border-radius: 15px;
  box-shadow: var(--shadow-button);
}

.total-container-product-bag {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}

.total-title-product-bag {
  color: var(--primary-color);
  font-size: 1.4em;
  padding: 16px;
  
}

.quantity-container-product-bag {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quantity-product-bag {
  color: var(--primary-color);
  font-size: 2em;
}

.quantity-title-product-bag {
   
}

.trash-product-bag {
 
  margin:10px;
}