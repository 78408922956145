.modal-terms-content {
    background-color: rgb(255, 255, 255);
    position: relative;
    width: 100%;
    min-width: 400px;
    height: 100vh;
    margin: auto;
    padding-bottom: 10px;
    border-radius: var(--border-radius);
}

.modal-terms {
    background-color: rgb(255, 255, 255, 0);
    z-index: 300;
    margin-top: var(--height-navbar);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 8%;
    right: 0;
    left: 0;
    overflow: hidden;
    padding-top: 10px;
}

.close-modal-terms {
    background-color: var(--secondary-color-light);
    width: 10vw;
    height: 10vw;
    border-radius: 10vw;
    display: flex;
    display: none;
    flex-direction: 'row';
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
}

.close-modal-terms:active {
    transform: translateY(0.5px);
}

.close-icon-terms {
    fill: white;
    stroke: #fff;
    stroke-width: 8px;
    height: 5vw;
    width: 5vw;
}

.content-data-terms {
    min-height: 60vh;
    max-height: 50vh;
    width: 100%;
    margin: auto;
}

.text-all-terms-and-conditions {
    font-size: 1rem
}

.title-terms-and-conditions {
    color: var(--secondary-color);
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.button-accept-termns {
    width: 60%;
    margin: auto;
    margin-top: 5%;
}

@media only screen and (max-width: 600px) {

    .content-data-terms {
        min-height: 50vh;
        width: 90%;
        margin: auto;
    }
}

@media only screen and (min-width: 600px) {
    .content-data-terms {
        min-height: 50vh;
        width: 90%;
        margin: auto;
    }
}

@media only screen and (min-width: 768px) {
    .modal-terms-content {
        width: 90%;
        min-width: 400px;
        padding-bottom: 10px;
        border-radius: var(--border-radius);
        height: auto;
        padding-bottom: 5%;
    }

    .close-modal-terms {
        width: 23px;
        height: 23px;
        border-radius: 50px;
        display: flex;
        margin-bottom: 10px;
    }

    .close-icon-terms {
        fill: white;
        stroke: #fff;
        stroke-width: 14px;
        height: 10px;
        width: 10px;
    }

    .reject-button {
        display: none;
    }

    .button-accept-termns {
        width: 30%;
        margin: auto;
        margin-top: 5%;
    }
}

@media only screen and (min-width: 992px) {
    .modal-terms-content {
        width: 80%;
        min-width: 400px;
        padding-bottom: 10px;
        border-radius: var(--border-radius);
        height: auto;
        padding-bottom: 5%;
    }
}

@media only screen and (min-width: 1200px) {
    .modal-terms-content {
        width: 70%;
        min-width: 400px;
        padding-bottom: 10px;
        border-radius: var(--border-radius);
        height: auto;
        padding-bottom: 5%;
    }
}