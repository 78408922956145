.container-footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    background-color: #fcc3d2;
    padding: 16px 120px 0 120px; /* Solo en el eje vertical */
    gap: 0px;
    margin: 0 auto; /* Centra el contenedor */
    justify-content: center;
}

.footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #80225f;
    max-width:500px ;
    justify-content: center;
}

.footer-logo {
    width: 150px;
    margin-bottom: 10px;
}

.footer-text {
    font-size: 14px;
    font-weight: 400;
    color: #80225f;
    font-family: 'Montserrat', sans-serif;
    
}

.footer-title {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 8px;
}

.footer-contact, .footer-links {
    list-style: none;
    padding: 0;
    font-size: 13px;
    line-height: 1.6;
}

.footer-contact li, .footer-links li {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    justify-content: center;
}

.footer-icon {
    width: 15px;
}

.footer-social {
    display: flex;
    gap: 10px;
    margin: 8px 8px 35px 8px;
}

.footer-social-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    padding: 4px;
    filter: invert(83%) sepia(-1%) saturate(3772%) hue-rotate(291deg) brightness(116%) contrast(99%);
}

/* Media Queries */
@media screen and (max-width: 768px) {
    .footer-logo {
        width: 150px;
    }

    .footer-social-icon {
        width: 30px;
    }
    .container-footer {
        gap: 8px;
        padding: 16px 24px 8px 30px;
    }
    .footer-social {
        margin: 8px 8px 20px 8px;
    }
}

@media screen and (max-width: 480px) {
    .container-footer {
        grid-template-columns: 1fr;
        padding: 30px;
        padding: 16px 24px 8px 30px;
    }
    .footer-title {
        font-size: 14px;
    }

    .footer-text, .footer-contact li, .footer-links li {
        font-size: 12px;
    }
    .footer-social {
        margin: 8px 8px 16px 8px;
    }
}
